div .iconsSize {
    font-size: 18px;
}

div .listItemIcon {
    min-width: 25px;
}
div .iconSuccess {
    color: var(--success-color);
}
div .iconError {
    color: var(--error-color);
}
div .iconDefault {
    color: var(--grey-100-color);
}
