@import 'fonts.css';

:root {
    /* Cores */
    --primary-color: #450754;
    --primary-light-color: #c178fd;
    --primary-dark-color: #220227;
    --primary-10-color: #8400ff18;
    --grey-50-color: #f8f8f8;
    --grey-100-color: #f0f0f0;
    --grey-300-color: #e0e0e0;
    --grey-400-color: #c0c0c0;
    --grey-600-color: #a0a0a0;
    --grey-700-color: #808080;
    --grey-800-color: #3f3f3f;
    --grey-900-color: #404040;
    --white-color: #ffffff;
    --black-color: #120403;
    --error-color: #ed084d;
    --error-light-color: #f90850;
    --success-color: #05b90e;
    --success-light-color: #06cc10;
    --warning-color: #edbb08;
    --info-color: #0275d8;
    --blue-color: #004cff22;

    /* Font Family */

    --font-family-regular: 'Neue-Haas', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    --font-family-bold: 'Zumee-SemiBold', sans-serif;
}
